/*----------------------------------------------------------------------------*\
    $HEADER
    top of page
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        @include media-query(lap){
            .header__pri ul{
                display: none;
            }
            .logo-holder{
                position: absolute;
            }
        }


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .header__mob{
                display: block;
            }
            .header__pri ul{
                display: none;
            }
            .header__nav{
                padding-left: 40px;
            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
