@charset "UTF-8";
/*----------------------------------------------------------------------------*\
        theme.scss
        Theme stylesheet, extends Blocss CSS Framework
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
        CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    README
    INCLUDES............Include various sass files
    BASE................High-level elements like 'html', 'body', etc.
    LAYOUT..............Structural styling
    MODULES.............Reusable, modular parts of the page
    */




/*----------------------------------------------------------------------------*\
    $INCLUDES
    Include various sass files
\*----------------------------------------------------------------------------*/

    /**
     * If you use compass enable this
     */
    @import "vars";
    @import "../lib/blocss/blocss";
    @import "../lib/blocss-arrange/arrange";
    @import "../lib/blocss-buttons/buttons";
    @import "../lib/blocss-flexembed/flexembed";
    @import "../lib/blocss-grid/grid";
    @import "../lib/blocss-dimensions/dimensions";
    @import "../lib/blocss-island/island";
    @import "../lib/blocss-media/media";
    @import "../lib/blocss-navigation/navigation";
    @import "../lib/blocss-helpers/helpers";

    /**
     * Theme includes
     */
    @import "theme/base/_color.scss";
@import "theme/base/_font.scss";
@import "theme/base/_toplevel.scss";
@import "theme/layout/_footer.scss";
@import "theme/layout/_header.scss";
@import "theme/layout/_layer.scss";
@import "theme/modules/_helpers.scss";
@import "theme/nav/_main.scss";


/* Theme */

a {
    text-decoration: none;
    color: color(brand);
}

p {
    font-size: 15px;
}

h1,
h2,
h3,
h4,
h5 {
    color: color(brand);
}

h2 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: $space;
    @include font-size($alpha);
}

h4 {
    font-weight: 600;
    @include font-size($gamma);
    margin-bottom: 10px;
    margin-top: 26px;
}

.btn {
    display: inline-block;
    padding: 8px 15px;
    background: color(brand);
    color: color(blank);
    margin-bottom: $space;
    border: none;
}

.header {
    a {
        text-decoration: none;
        color: color(brand-sec);
    }

    a:active,
    a:focus,
    a:hover {
        text-decoration: none;
    }

    .logo {
        position: absolute;
        top: -20px;

        img,
        object {
            min-width: 210px;
        }
    }

    .phone-number {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 60px;

        i {
            font-size: 25px;
            vertical-align: middle;
        }

        span {
            font-weight: bold;
        }
    }
}

.content {
    .title {
        width: 70%;
        margin-top: 5px;
    }

    .message {
        padding: 80px 0;
    }
}

.footer {
    .link {
        color: white;
        padding: 40px;

        i {
            font-size: 40px;
            vertical-align: middle;
            margin-right: 15px;
        }

        span {
            font-size: 17px;
            font-weight: bold;
        }
    }
}
@include media-query(palm) {
    .layer {
        padding: 0 !important;
    }

    .header {
        .logo {
            img,
            object {
                min-width: 50px;
            }
        }

        .phone-number {
            padding: 10px 0;
        }
    }
}
